import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Spin, Modal, Button, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const DashboardCompliance = () => {
  const [loading, setLoading] = useState(true);
  const [compliances, setCompliances] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [size, setSize] = useState('large');
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [selectedCompliance, setSelectedCompliance] = useState(null);

  useEffect(() => {
    const fetchCompliances = async () => {
      try {
        const response = await api.get('/compliance');
        console.log('response', response);
        setCompliances(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao buscar dados de compliance.');
        setLoading(false);
      }
    };

    fetchCompliances();
  }, []);

  const handlePreview = (url) => {
    setPreviewImage(url);
    setIsModalOpen(true);
  };

  const handleDownloadClick = (record) => {
    setSelectedCompliance(record);
    setSecondModalOpen(true);
  };

  const generatePDF = () => {
    const modalContent = document.getElementById('second-modal-content');
    html2canvas(modalContent, { scale: 10 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'JPEG', 10, 10, 190, 120);
      pdf.save('compliance.pdf');
    });
  };

  const columns = [
    {
      title: 'Nome da Empresa',
      dataIndex: 'nomeEmpresa',
      key: 'nomeEmpresa',
      className: styles.columnEllipsis,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.columnEllipsis,
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      className: styles.columnEllipsis,
    },
    {
      title: 'Capital Social Constituído',
      dataIndex: 'capitalSocialConstituido',
      key: 'capitalSocialConstituido',
      className: styles.columnEllipsis,
    },
    {
      title: 'Download',
      key: 'downloadDocumento',
      render: (_, record) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size={size}
          onClick={() => handleDownloadClick(record)}
        />
      ),
    },
  ];

  return (
    <div className={`${styles.container} ${styles.tableContainer}`}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Spin spinning={loading}>
            <Table dataSource={compliances} columns={columns} rowKey="id" />
          </Spin>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        title="Visualizar Documento"
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <img alt="document" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Modal
        open={secondModalOpen}
        title="Resumo das Informações"
        onCancel={() => setSecondModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setSecondModalOpen(false)}>
            Fechar
          </Button>,
          <Button key="generatePDF" type="primary" onClick={generatePDF}>
            Gerar PDF
          </Button>,
        ]}
      >
        {selectedCompliance && (
          <div id="second-modal-content">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Nome da Empresa:</label>
                <Input value={selectedCompliance.nomeEmpresa} />
              </Col>
              <Col span={12}>
                <label>Telefone:</label>
                <Input value={selectedCompliance.telefone} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Email:</label>
                <Input value={selectedCompliance.email} />
              </Col>
              <Col span={12}>
                <label>Capital Social Constituído:</label>
                <Input value={selectedCompliance.capitalSocialConstituido} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Comprovantes do Capital Social:</label>
                <Input value={selectedCompliance.comprovantesCapitalSocial} />
              </Col>
              <Col span={12}>
                <label>Capacidade Financeira dos Sócios:</label>
                <Input value={selectedCompliance.capacidadeFinanceiraSocio} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Contratos de Empréstimo ou Mútuo:</label>
                <Input value={selectedCompliance.contratoEmprestimoMutuo} />
              </Col>
              <Col span={12}>
                <label>Local de Depósito do Capital:</label>
                <Input value={selectedCompliance.localDepositoCapital} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Estrutura do Local:</label>
                <Input value={selectedCompliance.estruturaLocal} />
              </Col>
              <Col span={12}>
                <label>Contratos de Locação:</label>
                <Input value={selectedCompliance.contratosLocacao} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Contas de Imóveis:</label>
                <Input value={selectedCompliance.contasImoveis} />
              </Col>
              <Col span={12}>
                <label>Registro de Faturamento:</label>
                <Input value={selectedCompliance.faturamentoRegular} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Armazenamento de Mercadorias:</label>
                <Input value={selectedCompliance.armazenamentoMercadorias} />
              </Col>
              <Col span={12}>
                <label>Procedimentos de Fiscalização:</label>
                <Input value={selectedCompliance.procedimentoFiscalizacao} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Envolvimento em Processos Judiciais:</label>
                <Input value={selectedCompliance.envolvimentoProcessoJudicial} />
              </Col>
              <Col span={12}>
                <label>Funcionários Registrados:</label>
                <Input value={selectedCompliance.funcionariosRegistrados} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Recolhimentos Previdenciários:</label>
                <Input value={selectedCompliance.recolhimentosPrevidenciarios} />
              </Col>
              <Col span={12}>
                <label>Responsável pelo Comércio Exterior:</label>
                <Input value={selectedCompliance.responsavelComercioExterior} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Documentação Contábil:</label>
                <Input value={selectedCompliance.documentacaoContabil} />
              </Col>
              <Col span={12}>
                <label>Livros Diário e Razão:</label>
                <Input value={selectedCompliance.livrosDiarioRazao} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Extratos Financeiros:</label>
                <Input value={selectedCompliance.extratosContasFinanceiras} />
              </Col>
              <Col span={12}>
                <label>Procuração para Operação de Contas:</label>
                <Input value={selectedCompliance.procuracaoOperacaoConta} />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DashboardCompliance;
