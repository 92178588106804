import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Tag, Select, Spin, Modal, Button, Tooltip, Input, Breadcrumb, DatePicker, Switch } from 'antd';
import { DownloadOutlined, HomeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const { Option } = Select;

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [size, setSize] = useState('large');
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);

  const cidadeUf = selectedCliente ? `${selectedCliente.cidade}/${selectedCliente.estado}` : '';

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await api.get('/clientes');
        setClientes(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao buscar clientes.');
        setLoading(false);
      }
    };

    fetchClientes();
  }, []);

  const handleEtapaChange = async (value, record) => {
    try {
      const updatedClientes = clientes.map((cliente) => (cliente.id === record.id ? { ...cliente, etapa: value } : cliente));
      setClientes(updatedClientes);

      await api.put(`/clientes/${record.id}`, { etapa: value });
      toast.success('Etapa atualizada com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar etapa.');
    }
  };

  const handleDateChange = async (date, dateString, record) => {
    try {
      const updatedClientes = clientes.map((cliente) =>
        cliente.id === record.id ? { ...cliente, dataConsulta: dateString } : cliente
      );
      setClientes(updatedClientes);

      await api.put(`/clientes/${record.id}`, { dataConsulta: dateString });
      toast.success('Data da consulta atualizada com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar data da consulta.');
    }
  };

  const handlePresencialChange = async (checked, record) => {
    try {
      const updatedClientes = clientes.map((cliente) =>
        cliente.id === record.id ? { ...cliente, isPresencial: checked } : cliente
      );
      setClientes(updatedClientes);

      await api.put(`/clientes/${record.id}`, { isPresencial: checked });
      toast.success('Status de atendimento presencial atualizado com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar status de atendimento presencial.');
    }
  };

  const handlePreview = (url) => {
    setPreviewImage(url);
    setIsModalOpen(true);
  };

  const handleDownloadClick = (record) => {
    setSelectedCliente(record);
    setSecondModalOpen(true);
  };

  const generatePDF = () => {
    const modalContent = document.getElementById('second-modal-content');
    html2canvas(modalContent, { scale: 10 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'JPEG', 10, 10, 190, 120);
      pdf.save('cliente.pdf');
    });
  };

  const columns = [
    {
      title: 'Nome Completo',
      dataIndex: 'nomeCliente',
      key: 'nomeCliente',
      className: styles.columnEllipsis,
    },
    {
      title: 'Etapa',
      key: 'etapa',
      dataIndex: 'etapa',
      render: (_, record) => (
        <Select defaultValue={record.etapa} style={{ width: 120 }} onChange={(value) => handleEtapaChange(value, record)}>
          <Option value='contato'>Contato</Option>
          <Option value='negociacao'>Negociação</Option>
          <Option value='fechado'>Fechado</Option>
        </Select>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.columnEllipsis,
    },
    {
      title: 'Presencial',
      dataIndex: 'isPresencial',
      key: 'isPresencial',
      render: (_, record) => (
        <Switch
          checked={record.isPresencial}
          onChange={(checked) => handlePresencialChange(checked, record)}
        />
      ),
    },
    {
      title: 'Data da Consulta',
      dataIndex: 'dataConsulta',
      key: 'dataConsulta',
      width: 200, // Ajusta a largura da coluna
      render: (_, record) => (
        <DatePicker
          style={{ width: '100%' }}  // Define a largura do DatePicker para ocupar a largura da coluna
          format="DD/MM/YYYY"
          value={record.dataConsulta ? moment(record.dataConsulta) : null}
          onChange={(date, dateString) => handleDateChange(date, dateString, record)}
        />
      ),
    },
    
    
    {
      title: 'Endereço',
      dataIndex: 'enderecoCompleto',
      key: 'enderecoCompleto',
      render: (_, record) => {
        const enderecoCompleto = `${record.logradouro}, ${record.numero}, ${record.bairro}, ${record.cidade}, ${record.estado}, ${record.cep}`;
        return (
          <Tooltip title={enderecoCompleto}>
            <span className={styles.truncatedText}>{enderecoCompleto}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      className: styles.columnEllipsis,
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cpfCnpj',
      key: 'cpfCnpj',
      className: styles.columnEllipsis,
    },
    {
      title: 'Descrição',
      dataIndex: 'assuntoConsulta',
      key: 'assuntoConsulta',
      className: styles.columnEllipsis,
    },
    {
      title: 'Visualizar Documento',
      key: 'visualizarDocumento',
      render: (_, record) =>
        record.urlDocumentos &&
        record.urlDocumentos.length > 0 && (
          <Button onClick={() => handlePreview(`https://credx-bucket.s3.amazonaws.com/${record.urlDocumentos[0]}`)} type='primary'>
            Visualizar
          </Button>
        ),
    },
    {
      title: 'Download',
      key: 'downloadDocumento',
      render: (_, record) => <Button type='primary' icon={<DownloadOutlined />} size={size} onClick={() => handleDownloadClick(record)} />,
    },
  ];

  return (
    <div>
      <Breadcrumb
        items={[
          {
            href: '',
            title: <HomeOutlined />,
          },
          {
            href: '',
            title: (
              <>
                <span>Agendamentos</span>
              </>
            ),
          },
          {
            title: 'Consulta',
          },
        ]}
      />
      <div className={`${styles.container} ${styles.tableContainer}`}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Spin spinning={loading}>
              <Table dataSource={clientes} columns={columns} rowKey='id' />
            </Spin>
          </Col>
        </Row>
        <Modal open={isModalOpen} title='Visualizar Documento' footer={null} onCancel={() => setIsModalOpen(false)}>
          <img alt='document' style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Modal
          open={secondModalOpen}
          title='Resumo das Informações'
          onCancel={() => setSecondModalOpen(false)}
          footer={[
            <Button key='back' onClick={() => setSecondModalOpen(false)}>
              Fechar
            </Button>,
            <Button key='generatePDF' type='primary' onClick={generatePDF}>
              Gerar PDF
            </Button>,
          ]}
        >
          {selectedCliente && (
            <div id='second-modal-content'>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Nome Completo:</label>
                  <Input value={selectedCliente.nomeCliente} />
                </Col>
                <Col span={12}>
                  <label>CPF/CNPJ:</label>
                  <Input value={selectedCliente.cpfCnpj} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Email:</label>
                  <Input value={selectedCliente.email} />
                </Col>
                <Col span={12}>
                  <label>Presencial:</label>
                  <Input value={selectedCliente.isPresencial ? 'Sim' : 'Não'} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Telefone:</label>
                  <Input value={selectedCliente.telefone} />
                </Col>
                <Col span={12}>
                  <label>Etapa:</label>
                  <Input value={selectedCliente.etapa} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Data da Consulta:</label>
                  <Input value={selectedCliente.dataConsulta ? moment(selectedCliente.dataConsulta).format('DD/MM/YYYY') : ''} />
                </Col>
                <Col span={12}>
                  <label>Logradouro:</label>
                  <Input value={selectedCliente.logradouro} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <label>N°:</label>
                  <Input value={selectedCliente.numero} />
                </Col>
                <Col span={7}>
                  <label>CEP:</label>
                  <Input value={selectedCliente.cep} />
                </Col>
                <Col span={12}>
                  <label>Bairro:</label>
                  <Input value={selectedCliente.bairro} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Cidade/UF:</label>
                  <Input value={cidadeUf} />
                </Col>
                <Col span={12}>
                  <label>Assunto:</label>
                  <Input value={selectedCliente.assuntoConsulta} />
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Dashboard;
