import React, { useState } from 'react';
import { Steps, Button, Form, Input, Radio, Row, Col, message } from 'antd';
import InputMask from 'react-input-mask';
import styles from './style.module.scss';
import bg from '../../assets/bg.png';
import { api } from '../../services/apiClient';

const { Step } = Steps;

const QuestionarioCompliance = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    nomeCompleto: '',
    nomeEmpresa: '',
    telefone: '',
    capitalSocialConstituido: '',
    comprovantesCapitalSocial: '',
    capacidadeFinanceiraSocio: '',
    contratoEmprestimoMutuo: '',
    localDepositoCapital: '',
    estruturaLocal: '',
    contratosLocacao: '',
    contasImoveis: '',
    faturamentoRegular: '',
    armazenamentoMercadorias: '',
    procedimentoFiscalizacao: '',
    envolvimentoProcessoJudicial: '',
    funcionariosRegistrados: '',
    recolhimentosPrevidenciarios: '',
    responsavelComercioExterior: '',
    documentacaoContabil: '',
    livrosDiarioRazao: '',
    extratosContasFinanceiras: '',
    procuracaoOperacaoConta: '',
    titularContasBancarias: '',
    compatibilidadeValores: '',
    movimentacoesBancarias: '',
    registroNotasFiscais: '',
    origemRecursosImportacao: '',
    condicoesFinanciamento: '',
    identificacaoProvedorRecursos: '',
    contratoEmprestimoImportacao: '',
    comprovanteTransferenciaImportacao: '',
    responsavelNegociacaoImportacao: '',
    meioNegociacaoImportacao: '',
    vinculoPrevioImportador: '',
    descontoValorExportador: '',
    contratoCambio: '',
    valoresImportacaoCambio: '',
    contratoSeguroMercadoria: '',
    contratoTransporteInternacional: '',
    prestacaoServicosDespachante: '',
    pagamentoCustosImportacao: '',
    patenteRegistrada: '',
    checagemRegistroMarca: '',
    ofertaMercadoria: '',
    tempoNacionalizacaoRevenda: '',
    checagemIdoneidadeEmpresas: '',
    analiseCreditoClientes: '',
    tributacaoDeclaradaRevenda: '',
    estadoNacionalizacao: '',
    formaEncontrarFornecedores: '',
    responsavelAssinaturaInvoice: '',
    controleItinerarioTransporte: '',
    conformidadeAreasAtuacao: '',
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onSubmit = async () => {
    try {
      const dataToSend = { ...formData };
      await api.post('/compliance', dataToSend);
      message.success('Cadastro realizado com sucesso!');
      setSuccess(true);
    } catch (error) {
      message.error('Erro ao realizar o cadastro.');
    }
  };

  const onChangeValue = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.augustofoto02}>
        <img src={bg} alt='augusto' />
      </div>
      <div className={styles.containerFormulario}>
        <div className={styles.steps}>
          <Steps current={currentStep} direction='horizontal'>
            {Array.from({ length: 6 }, (_, i) => ({ title: '' })).map((item, index) => (
              <Step key={index} />
            ))}
          </Steps>
        </div>

        <Form form={form} layout='vertical' autoComplete='off' style={{ width: '100%' }}>
          <div className={styles.stepsContent}>
            {currentStep === 0 && (
              <>
                <h2>QUESTIONÁRIO COMPLIANCE ADUANEIRO</h2>
                <p>
                  Como parte de nossos serviços prestados de consultoria, compliance e garantia do cumprimento das normas, solicitamos sua colaboração para o preenchimento deste questionário. Suas respostas são fundamentais para avaliar o efetivo funcionamento da empresa e das práticas de conformidade legal e contabilidade dentro desta.
                </p>
                <p>
                  <strong>Objetivo do Questionário e Confidencialidade dos Dados:</strong>
                </p>
                <p>
                  Este questionário tem como finalidade realizar uma avaliação e controle de risco aduaneiro sobre as práticas empresariais e contabilidade da empresa. Suas respostas serão utilizadas estritamente para fins de pesquisa interna e melhoria de nosso feedback. Todos os dados coletados serão tratados com a mais alta confidencialidade e não serão compartilhados ou divulgados de nenhuma forma a terceiros sem a sua autorização expressa.
                </p>
                <p>
                  <strong>Instruções:</strong>
                </p>
                <p>
                  Por gentileza, responder aos seguintes questionamentos acerca do efetivo funcionamento e da contabilidade da empresa, com a maior precisão possível, considerando a realidade de sua empresa.
                </p>
                <p>Seu feedback é indispensável para aprimorarmos nossos serviços e garantirmos a excelência e a conformidade em todas as etapas do compliance aduaneiro.</p>
                <p>Agradecemos por sua colaboração.</p>
                <p>
                  <strong>Informe os seguintes dados:</strong>
                </p>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='E-mail' name='email' rules={[{ required: true, message: 'Por favor, insira o e-mail' }]}>
                      <Input type='email' onChange={(e) => onChangeValue('email', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='Nome completo' name='nomeCompleto' rules={[{ required: true, message: 'Por favor, insira o nome completo' }]}>
                      <Input onChange={(e) => onChangeValue('nomeCompleto', e.target.value)} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='Nome da empresa' name='nomeEmpresa' rules={[{ required: true, message: 'Por favor, insira o nome da empresa' }]}>
                      <Input onChange={(e) => onChangeValue('nomeEmpresa', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='Telefone' name='telefone' rules={[{ required: true, message: 'Por favor, insira o telefone' }]}>
                      <InputMask mask='(99) 99999-9999' maskChar={null} value={formData.telefone} onChange={(e) => onChangeValue('telefone', e.target.value)}>
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {currentStep === 1 && (
              <>
                <h2>Acerca do efetivo funcionamento da empresa, responda:</h2>
                <Form.Item label='1) De que forma foi constituído o capital social da empresa? Integralizado pelos sócios? Ou através de recursos de terceiros? Explique de forma detalhada.'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('capitalSocialConstituido', e.target.value)} value={formData.capitalSocialConstituido} />
                </Form.Item>
                <Form.Item label='2) Há comprovantes sobre as eventuais transações que envolveram a integralização do capital social bem como comprovantes de transferências bancárias e/ou extrato bancário?'>
                  <Radio.Group onChange={(e) => onChangeValue('comprovantesCapitalSocial', e.target.value)} value={formData.comprovantesCapitalSocial}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='3) O(s) sócio(s) da empresa possui(em) capacidade financeira comprovada e declarada em imposto de renda para fins de integralização do capital social e/ou eventual aquisição de quotas da sociedade (em caso de alteração de quadro societário)?'>
                  <Radio.Group onChange={(e) => onChangeValue('capacidadeFinanceiraSocio', e.target.value)} value={formData.capacidadeFinanceiraSocio}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 2 && (
              <>
                <Form.Item label='4) Caso a integralização do capital social da empresa tenha sido feita mediante contrato de empréstimo ou mútuo, há contrato formalizado neste sentido? Existe a comprovação de renda do Mutuante que garanta a capacidade para o mútuo?'>
                  <Radio.Group onChange={(e) => onChangeValue('contratoEmprestimoMutuo', e.target.value)} value={formData.contratoEmprestimoMutuo}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='5) Onde se encontra depositado atualmente o capital social da empresa? Possui os extratos bancários?'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('localDepositoCapital', e.target.value)} value={formData.localDepositoCapital} />
                </Form.Item>
                <Form.Item label='6) Qual a estrutura do local em que a empresa está sediada (coworking, galpão, prédio comercial...)?'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('estruturaLocal', e.target.value)} value={formData.estruturaLocal} />
                </Form.Item>
              </>
            )}
            {currentStep === 3 && (
              <>
                <Form.Item label='7) Há contratos de locação ou escritura referentes aos locais em que a empresa é sediada, e, eventualmente, possui filial e armazena suas mercadorias (inclui-se aqui eventuais armazéns, depósitos, escritórios, etc.)? Detalhar.'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('contratosLocacao', e.target.value)} value={formData.contratosLocacao} />
                </Form.Item>
                <Form.Item label='8) As contas de água, luz, telefone e IPTU dos imóveis em que a empresa opera e armazena as mercadorias estão em nome da empresa ou do socio ou terceiros?'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('contasImoveis', e.target.value)} value={formData.contasImoveis} />
                </Form.Item>
                <Form.Item label='9) A empresa vem registrando regularmente seu faturamento ou receita bruta, além de vendas de mercadorias ou prestação de serviços?'>
                  <Radio.Group onChange={(e) => onChangeValue('faturamentoRegular', e.target.value)} value={formData.faturamentoRegular}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 4 && (
              <>
                <Form.Item label='10) Onde são armazenadas as mercadorias importadas entre o período de nacionalização e revenda no mercado interno?'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('armazenamentoMercadorias', e.target.value)} value={formData.armazenamentoMercadorias} />
                </Form.Item>
                <Form.Item label='11) A empresa já passou por algum procedimento especial de fiscalização aduaneira ou canal cinza? Sofreu alguma penalidade aduaneira como por exemplo multa aduaneira ou perdimento?'>
                  <Radio.Group onChange={(e) => onChangeValue('procedimentoFiscalizacao', e.target.value)} value={formData.procedimentoFiscalizacao}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='12) A empresa já se envolveu ativa ou passivamente em algum processo judicial sobre questão alguma aduaneira?'>
                  <Radio.Group onChange={(e) => onChangeValue('envolvimentoProcessoJudicial', e.target.value)} value={formData.envolvimentoProcessoJudicial}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 5 && !success && (
              <>
                <h2>Por fim, acerca do procedimento de importação, responda:</h2>
                <Form.Item label='1) De onde provieram os recursos empregados na importação (próprio, empréstimos, financiamento, mútuo, ou outros)? Há registros e documentos comprobatórios da origem?'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('origemRecursosImportacao', e.target.value)} value={formData.origemRecursosImportacao} />
                </Form.Item>
                <Form.Item label='2) Em sendo o caso dos recursos empregados na importação provirem de empréstimos, financiamento, mútuo, ou outros, é possível a devida identificação da descrição das condições do financiamento (prazo de pagamento do principal, juros e encargos, margem adicional, valor de garantia, respectivos valores-base para cálculo, e parcelas não financiadas), além da forma de prestação e identificação dos bens oferecidos em garantia?'>
                  <Input.TextArea rows={4} onChange={(e) => onChangeValue('condicoesFinanciamento', e.target.value)} value={formData.condicoesFinanciamento} />
                </Form.Item>
              </>
            )}
            {success && (
              <div className={styles.successMessage}>
                <h2>Cadastro realizado com sucesso!</h2>
                <p>Entraremos em contato em breve.</p>
              </div>
            )}
          </div>
          {!success && (
            <div className='steps-action'>
              {currentStep < 5 && (
                <Button className={styles.buttonNextStep} onClick={nextStep}>
                  Próximo
                </Button>
              )}
              {currentStep === 5 && (
                <Button className={styles.buttonNextStep} onClick={onSubmit}>
                  Enviar
                </Button>
              )}
              {currentStep > 0 && (
                <Button className={styles.buttonback} onClick={prevStep}>
                  Voltar
                </Button>
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default QuestionarioCompliance;
