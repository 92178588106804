import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import SidebarOfficial from './Components/Sidebar';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Home from './Pages/Home';
import CadastroCliente from './Pages/CadastroCliente';
import ptBR from 'antd/lib/locale/pt_BR';
import Consultas from './Pages/Consultas';
import Icms from './Pages/Icms';
import DashboardIcms from './Pages/DashboardIcms';
import QuestionarioCompliance from './Pages/QuestionarioCompliance';
import DashboardCompliance from './Pages/DashboardCompliance';

const { Content } = Layout;

const customLocale = {
  ...ptBR,
  Pagination: {
    ...ptBR.Pagination,
    items_per_page: '/ página',
    colorPrimary: '#bda961',
  },
};

const tokenConfig = {
  token: {
    colorPrimary: '#bda961',
    colorPrimaryBorder: 'rgba(241, 221, 255, 0.53)',
    colorPrimaryHover: '#bda961',
  },
};

const App = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Router>
      <AuthProvider>
        <ConfigProvider locale={customLocale} theme={tokenConfig}>
          <Layout style={{ minHeight: '100vh' }}>
            {isAuthenticated() ? (
              <>
                <SidebarOfficial collapsed={collapsed} toggleCollapsed={toggleCollapsed} userLevel={0} />
                <Layout>
                  <Content style={{ margin: '2rem 16px' }}>
                    <Routes>
                      <Route path='/dashboard' element={<Dashboard />} />
                      <Route path='/consultas' element={<Consultas />} />
                      <Route path='/' element={<Navigate to="/consultas" />} />
                      <Route path='*' element={<Navigate to="/consultas" />} />
                      <Route path='/dashboard-icms' element={<DashboardIcms />} />
                      <Route path='/dashboard-compliance' element={<DashboardCompliance />} />
                     
                    </Routes>
                    <ToastContainer />
                  </Content>
                </Layout>
              </>
            ) : (
              <Suspense fallback={<div>Carregando...</div>}>
                <Routes>
                  <Route path='/login' element={<Login />} />
                  <Route path='/cadastro' element={<CadastroCliente />} />
                  <Route path='/' element={<Home />} />
                  <Route path='/icms' element={<Icms />} />
                  <Route path='/compliance' element={<QuestionarioCompliance />} />
                  <Route path='*' element={<Navigate to="/login" />} />
                </Routes>
              </Suspense>
            )}
          </Layout>
        </ConfigProvider>
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
};

export default App;
